<template>
  <el-dialog width="60%" :visible.sync="showModal" :destroy-on-close="true" :title="tile" :before-close="handleClose">
    <v-client-table class="hiaTable" ref="selectSpecialtiesGrid" id="selectSpecialtiesGrid" :data="specialties" :columns="gridColumns()" :options="tableOptions">
      <template slot="selected" slot-scope="props">
        <input :key="props.row[propertyValue]" type="checkbox" v-model="props.row.selected" @click="toggleSelection(props.row, $event)" />
      </template>
      <template slot="delete" slot-scope="props">
        <div style="text-align: center;">
          <i class="el-icon-close delete-button" title="Delete Claim" @click="deleteClaim(props.row)"></i>
        </div>
      </template>
      <template slot="providerSpecialty" slot-scope="props">
        <template v-if="propertyValue === 'serviceLocation'">
          <div v-if="!props.row.providerSpecialty">No Specialty</div>
          <span style="margin-bottom: 5px; margin-right: 5px; line-height: 14px;" v-else v-for="s in props.row.providerSpecialty.split('|')" :key="s"
            class="el-tag el-tag--mini el-tag--light">
            {{ s }}
          </span>
        </template>
        <template v-else>
          <div>
            {{ props.row.providerSpecialty ? props.row.providerSpecialty : 'No Specialty' }}
          </div>
        </template>
      </template>
      <template slot="providerClassification" slot-scope="props">
        <template v-if="propertyValue === 'serviceLocation'">
          <div v-if="!props.row.providerClassification">No Specialty</div>
          <span style="margin-bottom: 5px; margin-right: 5px;  line-height: 14px;" v-else v-for="s in props.row.providerClassification.split('|')" :key="s"
            class="el-tag el-tag--mini el-tag--light">
            {{ s }}
          </span>
        </template>
        <template v-else>
          <div>
            {{ props.row.providerClassification }}
          </div>
        </template>
      </template>
      <template slot="providerName" slot-scope="props">
        <template v-if="propertyValue === 'serviceLocation'">
          <div v-if="!props.row.providerName">No Provider</div>
          <span style="margin-bottom: 5px; margin-right: 5px;  line-height: 14px;" v-else v-for="s in props.row.providerName.split('|')" :key="s"
            class="el-tag el-tag--mini el-tag--light">
            {{ s }}
          </span>
        </template>
        <template v-else>
          <div>
            {{ props.row.providerName }}
          </div>
        </template>
      </template>
    </v-client-table>

    <div v-if="propertyValue === 'providerTaxonomy'" class="selectedSpecialties" style="margin-top: 30px;">
      <div>Selected Specialties:</div>
      <div v-if="selectedSpecialties.length === 0" style="height: 20px"><i>None</i></div>
      <span v-for="s in selectedSpecialties" :key="s.providerTaxonomy" class="el-tag el-tag--mini el-tag--light">
        {{ s.providerSpecialty ? s.providerSpecialty : s.providerClassification }}
      </span>
    </div>
    <!-- providers -->
    <div v-if="propertyValue === 'providerName'" class="selectedSpecialties" style="margin-top: 30px;">
      <div>Selected Providers:</div>
      <div v-if="selectedSpecialties.length === 0" style="height: 20px"><i>None</i></div>
      <span v-for="s in selectedSpecialties" :key="s[propertyValue]" class="el-tag el-tag--mini el-tag--light">
        {{ s[propertyValue] }}
      </span>
    </div>
    <!-- serviceLocations -->
    <div v-if="propertyValue === 'serviceLocation'" class="selectedSpecialties" style="margin-top: 30px;">
      <div>Selected Service Locations:</div>
      <div v-if="selectedSpecialties.length === 0" style="height: 20px"><i>None</i></div>
      <span v-for="s in selectedSpecialties" :key="s[propertyValue]" class="el-tag el-tag--mini el-tag--light">
        {{ s[propertyValue] }}
      </span>
    </div>
    <div style="margin-bottom: 30px; margin-top: 10px;">
      <div class="pull-right">
        <el-button-group>
          <el-button class="hiaButton" plain round size="mini" type="text" @click="handleClose">Close</el-button>
          <el-button class="hiaButton" plain round size="mini" @click="handleClear">Clear All</el-button>
          <el-button :disabled="selectedSpecialties.lenght === 0" class="hiaButton" plain round size="mini" @click="handleApplySpecialties">Continue</el-button>
        </el-button-group>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import hiaTable from '@/mixins/hiaTable'
  export default {
    name: 'SpecialtiesCompareDialog',
    props: ['tile', 'showModal', 'specialties', 'providerSpecialtiesSelect', 'propertyValue'],
    mixins: [hiaTable],
    data: function () {
      return {
        selectedSpecialties: [],
        tableOptions: {
          filterByColumn: true,
          listColumns: {},
          sortable: ['providerClassification', 'providerSpecialty', 'numberOfEmCodes'],
          orderBy: {
            // column: 'providerClassification',
            // ascending: true
          },
          columnsClasses: {},
          headings: {
            providerClassification: 'Classification',
            providerSpecialty: 'Specialty',
            numberOfEmCodes: 'EM Codes',
            providerName: 'Provider',
            serviceLocation: 'Service Location',
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'specialtiesSelectAllCheckbox'
                },
                on: {
                  click: e => {
                    this.selectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('closeModal')
      },
      handleClear() {
        // this.selectedSpecialties = []
        this.selectAll(false)
      },
      handleApplySpecialties() {
        this.$emit(
          'applySpecialties',
          this.selectedSpecialties.map(x => x[this.propertyValue])
        )
        // this.$emit('applySpecialties', this.selectedSpecialties.map(x => x.providerSpecialty ? x.providerSpecialty : x.providerClassification))
      },
      selectAll(checked) {
        let filteredRecords = []

        if (this.$refs.selectSpecialtiesGrid) {
          filteredRecords = this.$refs.selectSpecialtiesGrid.allFilteredData
        }

        const recs = this.specialties.filter(x => filteredRecords.some(y => y[this.propertyValue] === x[this.propertyValue]))
        // hiaTable.selectAll(recs, checked)

        recs.forEach(x => {
          x.selected = checked
          if (checked) {
            this.addSelection(x)
          } else {
            this.deleteSelection(x)
          }
        })
        this.specialties = [...this.specialties]
      },
      toggleSelection(row, e) {
        if (e.target.checked) {
          this.addSelection(row)
        } else {
          this.deleteSelection(row)
        }
      },
      addSelection(row) {
        this.selectedSpecialties.push(row)
      },
      deleteSelection(row) {
        const index = this.selectedSpecialties.findIndex(f => f[this.propertyValue] === row[this.propertyValue])
        if (index !== -1) {
          this.selectedSpecialties.splice(index, 1)
          const sourceIndex = this.$refs.selectSpecialtiesGrid.data.findIndex(f => f[this.propertyValue] === row[this.propertyValue])
          if (sourceIndex !== -1) {
            this.$refs.selectSpecialtiesGrid.data[sourceIndex].selected = false
          }
        }
      },
      gridColumns() {
        let headings = []
        switch (this.propertyValue) {
          case 'providerName':
            headings = ['selected', 'providerName', 'providerClassification', 'providerSpecialty', 'numberOfEmCodes']
            break
          case 'serviceLocation':
            headings = ['selected', 'serviceLocation', 'numberOfEmCodes']
            break
          case 'providerTaxonomy':
            headings = ['selected', 'providerClassification', 'providerSpecialty', 'numberOfEmCodes']
            break
          default:
            headings = ['selected', 'providerClassification', 'providerSpecialty', 'numberOfEmCodes']
        }
        return headings
      }
    },
    created: function () {
      if (this.providerSpecialtiesSelect.length) {
        this.specialties.forEach((v, i) => {
          if (this.providerSpecialtiesSelect.some(s => s === v[this.propertyValue])) {
            v.selected = true
            this.addSelection(v)
          }
        })
      }
    }
  }
</script>

<style scoped>
  >>>.selectedSpecialties>span.el-tag {
    margin: 5px 0px 2px 5px;
  }

  >>>.selectedSpecialties>span:first-of-type.el-tag {
    margin: 5px 0px 2px 0px;
  }

  >>>#selectSpecialtiesGrid tbody {
    word-break: break-word;
  }
</style>